import {React, useEffect, useState, useRef} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import PDFViewer from '../inc/PDFViewer';

import axios from '../api/axios';
import useAuth from "../hooks/useAuth";

const SUBCAT_FIELDS_URL = '/fields';
const GET_ITEM_URL = '/item';
const UPDATE_ITEM_URL = '/item';
const PROJECT_INFO_URL = '/project';
const ROOM_DETAILS_URL = '/getroom';
const FILE_DOWNLOAD_URL = '/download/';

function RoomEditItem() {
    // React Hooks
    const location = useLocation();
    const navigate = useNavigate();
    const { auth } = useAuth();
    const alertRef = useRef();

    // State variables
    const [inputs, setInputs] = useState({});                               // Inputs for item fields
    const [currentItem, setCurrentItem] = useState(null);                   // Current itemID
    const [selectedCat, setSelectedCat] = useState(null);                   // Selected item's cat name
    const [selectedSubCat, setSelectedSubCat] = useState(null);             // Selected item's subcat name
    const [labels, setLabels] = useState([null, null, null, null, null]);   // Field labels for the given item type (based on subcat ID fields and prop result)
    const [exitAction, setExitAction] = useState(null);                     // Action to perform on exit attempt (whether to warn if unsaved changes)
    const [projectName, setProjectName] = useState(null);                   // Project name fetched based on project ID
    const [roomData, setRoomData] = useState(null);                         // ??
    const [attachments, setAttachments] = useState([]);                     // Item attached files, if any
    const [dirtyInputs, setDirtyInputs] = useState(null);                   // Dirty flag for if inputs are changed
    const [success, setSuccess] = useState(false);                          // Success state for alert on save commit
    const [fileNames, setFileNames] = useState([]);                         // List of file names, if any attachments
    const [fileMap, setFileMap] = useState({});                             // Map of files, including generated and true filenames
    const [uploadComplete, setUploadComplete] = useState(true);             // Success state variable for file upload
    const [activeTab, setActiveTab] = useState(0);                          // Active tab for view; details or attachments

    // PDF Viewer state vars
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pdfData, setPdfData] = useState(null);
    const [imageData, setImageData] = useState(null);

    const viewerRef = useRef(null);
    const prevScrollPositionRef = useRef(0);

    // Supported image extensions for previewer
    const imageFormats = [".jpg", ".png", ".gif", ".jpeg"];

    const tabs = [
        { title: 'Item Details' },
        { title: 'Attachments' }
    ];

    // Function to handle tab selection
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };
    
    // Navigate back to project home if no room/project selected
    const params = new URLSearchParams(location.search);
    const itemID = params.get('itemID');
    useEffect(() => {
        if (!itemID) {
            navigate(-1);
        }
    }, [itemID, navigate]);

    // Get the existing item details; update the cat and subcat values (read-only)
    useEffect(() => {
        try {
            if (itemID) {
                axios.get(GET_ITEM_URL + "?itemID=" + itemID,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.accessToken}`
                    },
                    withCredentials: true
                }
                ).then(
                    response => {
                        const itemData = response?.data;
                        setCurrentItem(itemData);
                        
                        setSelectedCat(itemData.catName);
                        setSelectedSubCat(itemData.subCatName);
                        setInputs({
                            field1: itemData.field1 ?? '',
                            field2: itemData.field2 ?? '',
                            field3: itemData.field3 ?? '',
                            field4: itemData.field4 ?? '',
                            field5: itemData.field5 ?? '',
                            field6: itemData.field6 ?? '',
                            notes: itemData.note
                        });

                        if (itemData.files) {
                            setAttachments(JSON.parse(itemData.files));
                            setFileMap(JSON.parse(itemData.files));
                        }
                        else {
                            setAttachments([]);
                        }
                        setDirtyInputs(false);
                    }
                );
            }
        }
        catch (err) {
            console.log(err);
        }
    }, [itemID, auth]);

    // Get the project name from the item's projectID
    useEffect(() => {  
        try {
            if (currentItem?.projectID) {
                axios.get(PROJECT_INFO_URL + "?projectID=" + currentItem.projectID,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.accessToken}`
                    },
                    withCredentials: true
                }
                ).then(
                    response => setProjectName(response?.data?.project?.name)
                );
            }
        }
        catch (err) {
            console.log(err);
        }
    }, [currentItem, auth]);
    
    // Get the room details to figure out room type string; store in effect hook
    useEffect(() => {
        try {
            if (currentItem?.roomID) {
                axios.get(ROOM_DETAILS_URL + "?roomID=" + currentItem.roomID,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.accessToken}`
                    },
                    withCredentials: true
                }
                ).then(
                    response => setRoomData(response?.data)
                );
            }
        }
        catch (err) {
            console.log(err);
        }
    }, [currentItem, auth]);

    // Use the current item's subcat ID to look up fields
    useEffect(() => {
        if (currentItem?.subcatID) {
            // Get the properties (fields) for this subcat; show the respective fields
            try {
                axios.get(SUBCAT_FIELDS_URL + "?subCatID=" + currentItem.subcatID,
                    {
                        headers: { 'Content-Type': 'application/json' }
                    }
                ).then(
                    response => {
                        const fieldList = response?.data.map(field => field.label);
                        setLabels(fieldList);
                    }
                )
            }
            catch (err) {
                console.log(err);
            }
        }
    }, [currentItem]);

    // Precondition check for back buttons - if form is not empty, confirm with modal and continue if user abandons changes
    const updateExitTarget = (event) => {
        event.preventDefault();
        const trigger = event.target.id;
        if (trigger === 'backToRoom') {
            if (currentItem?.roomID) {
                setExitAction(`/roomitems?roomID=${currentItem.roomID}`);
            }
        }
    }

    const performExit = (event) => {
        if (exitAction) {
            navigate(exitAction);
        }
        else {
            navigate(-1);
        }
    };
    
    
    // Navigate back to room home on button press
    const roomHome = (event) => {
        event.preventDefault();
        if (currentItem.roomID) {
            navigate(`/roomitems?roomID=${currentItem.roomID}`);
        }
        else {
            navigate(-1);
        }
    }

    const clearFields = (event) => {
        event.preventDefault();
        setInputs('');
    };

    // Update values when field is changed
    const updateInput = (event) => {
        setDirtyInputs(true);
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    };

    const updateItem = (event) => {
        event.preventDefault();
        try {
            console.log('send update: filemap');
            
            console.log(fileMap);
            axios.post(UPDATE_ITEM_URL,
                JSON.stringify({itemID, inputs, files: fileMap}),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.accessToken}`
                    },
                    withCredentials: true
                }
            ).then(function (response) {
                if (response?.status === 200) {
                    setSuccess(true);
                    setDirtyInputs(false);
                    navigate(`/roomitems?roomID=${currentItem.roomID}`);
                }
                else {
                   console.log('Unexpected response', response);
                }
            });
        }
        catch (err) {
            if (err) {
                console.log(err);
            }
        }
    };

    // Download files
    const downloadFile = (event) => {
        event.preventDefault();
        console.log(event.target.name);
        console.log(event.target.id);
        try {
            axios.get(FILE_DOWNLOAD_URL + event.target.id,
                {
                    responseType: 'blob',
                    headers: {
                        'Authorization': `Bearer ${auth.accessToken}`
                    },
                    withCredentials: true
                }
            ).then(response => {

                // If the file is a PDF, send it to the PDF Viewer
                if (event.target.id.toLowerCase().includes('.pdf')) {
                    // Reset the page counter to 1 to avoid invalid page access
                    setPageNumber(1);
                    var PDFreader = new FileReader();
                    PDFreader.readAsDataURL(response.data); 
                    PDFreader.onloadend = function() {
                        var base64data = PDFreader.result.split(',')[1];
                        setPdfData(base64data);
                    }
                }
                // Or, if it's an image, preview that
                else if (imageFormats.some(format => event.target.id.toLowerCase().includes(format))) {
                    // Read in the image and dump it to a base64 string for the previewer
                    var imageReader = new FileReader();
                    imageReader.readAsDataURL(response.data); 
                    imageReader.onloadend = function() {
                        var base64img = 'data:' + imageReader.result;
                        setImageData(base64img);
                    }
                }
                // Otherwise just download the file
                else {
                    // create file link in browser's memory
                    const href = URL.createObjectURL(response.data);
    
                    // create "a" HTML element with href to file & click
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', event.target.name);
                    document.body.appendChild(link);
                    link.click();
    
                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                }
            })
            .catch(error => {
                // Handle errors
                console.error('Error downloading file:', error);
            });
        }
        catch (err) {
            if (err) {
                console.log(err);
            }
        }
        
    }

    // Event listener for file selection
    const handleFileChange = (event) => {

        // Reset the upload complete flag to false
        setUploadComplete(false);
        setFileNames([]);

        const files = event.target.files;
        if (files && files.length > 0) {
            // Check if there are already files attached
            console.log("inputs.files");
            console.log(attachments);

            // Update the state variable with the new list of file names
            const names = Array.from(files).map(file => file.name);
            console.log('update files set files where files is files:', names)
            setFileNames(names);

            // Bang the endpoint to upload the files
            uploadFiles(files);

            // File change means inputs are dirty
            setDirtyInputs(true);
        }
        else {
            setUploadComplete(true);
            setFileNames([]);
        }
    }

    const uploadFiles = async (files) => {

        // Prepare form data for POST request and add files
        const formData = new FormData();
        Object.keys(files).forEach(key => {
            formData.append(files.item(key).name, files.item(key))
        })
    
        // Execute POST request
        const response = await axios.post('/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${auth.accessToken}`
          }
        });

        // Parse response to verify files were received
        if (response.data.length > 0) {
            const uploads = response.data.map(item => item.filename);

            // Check if all uploaded files match the attachment list
            if (fileNames.every((element, index) => element === uploads[index])) {
                setUploadComplete(true);

                // Create a file manifest for the database
                const manifest = response.data.reduce((acc, item) => {

                    // Shorten the filename if it is too long; preserve the extension & append it
                    var file_label = item.filename;
                    if (item.filename.length > 36) {
                        // Extract the extension (if there is one)
                        const lastIndex = item.filename.lastIndexOf('.');

                        // Shorten the file name (sans extension)
                        file_label = item.filename.substring(0, 31) + '[...]';

                        // Append the extension if there is one
                        if (lastIndex !== -1) {
                            file_label += item.filename.substring(lastIndex);
                        }
                    }
                    acc[file_label] = item.remote;
                    return acc;
                }, {});

                // Append the new files to the current files, if any
                setFileMap({...attachments, ...manifest});
                console.log('Filemap updated:');
                console.log(fileMap);
            }
            else {
                console.log('mismatched file manifest - should have cleared?');
                console.log(fileNames);
                console.log(uploads);
            }
        }
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        console.log(numPages);
    };

    const onPageLoadSuccess = () => {
        if (viewerRef.current) {
            viewerRef.current.scrollTo(0, prevScrollPositionRef.current);
        }
    };

    const pdfNavControls = (event) => {
        const control = event.target.id;

        switch (control) {
            case 'first':
                setPageNumber(1);
                break;
            case 'prev':
                if (pageNumber > 1) {
                    setPageNumber(pageNumber - 1);
                }
                break;
            case 'next':
                if (pageNumber < numPages) {
                    setPageNumber(pageNumber + 1);
                }
                break;
            case 'last':
                setPageNumber(numPages);
                break;
            
            case 'close':
                setPdfData(null);
                break;
            default:
                break;
        }
        prevScrollPositionRef.current = viewerRef.current.scrollTop;
    };

    
    
    // Array of tab content components
    const tabContentComponents = [
        <div className='row g-3'>
            <div className="col-6">
                <label htmlFor="projectName" className="form-label">Project Name</label>
                <input disabled id="projectName" value={projectName || ''} className="form-control" />
            </div>
            <div className="col-6">
                <label htmlFor="roomName" className="form-label">Room Name</label>
                <input disabled id="roomName" value={roomData?.roomName || ''} className="form-control" />
            </div>

            <div className="col-12">
                <label htmlFor="category" className="form-label">Category</label>
                <input disabled id="category" value={selectedCat || ''} className="form-control" />
            </div>

            <div className="col-12">
                <label htmlFor="subCategory" className="form-label">Sub Category</label>
                <input disabled id="subCategory" value={selectedSubCat || ''} className="form-control" />
            </div>

            {/* Element 0 - Manufacture/Note/Purchased */}
            {labels[0] && 
            <div className="col-md-12">
                <label htmlFor="item" className="form-label">{labels[0]}</label>
                <input type="text" className="form-control"
                id="field1" name="field1"
                maxLength="255"
                autoComplete="on"
                value={inputs.field1 || ''}
                onChange={updateInput}/>
            </div>
            }
            
            {/* Element 1 - Color/Series/Note/Model */}
            {labels[1] && 
            <div className="col-md-12">
                <label htmlFor="manufacturer" className="form-label">{labels[1]}</label>
                <input type="text" className="form-control"
                id="field2" name="field2"
                maxLength="255"
                autoComplete="on"
                value={inputs.field2 || ''}
                onChange={updateInput}/>
            </div>
            }
            
            {/* Element 2 - Purchased/Note/Style/ExtColor */}
            {labels[2] && 
            <div className="col-md-12">
                <label htmlFor="model" className="form-label">{labels[2]}</label>
                <input type="text" className="form-control"
                id="field3" name="field3"
                maxLength="255"
                autoComplete="on"
                value={inputs.field3 || ''}
                onChange={updateInput}/>
            </div>
            }
            
            {/* Element 3 - Purchased/Note */}
            {labels[3] && 
            <div className="col-md-12">
                <label htmlFor="modelNumber" className="form-label">{labels[3]}</label>
                <input type="text" className="form-control"
                id="field4" name="field4"
                maxLength="255"
                autoComplete="on"
                value={inputs.field4 || ''}
                onChange={updateInput}/>
            </div>
            }
            
            {/* Element 4 - IntColor */}
            {labels[4] && 
            <div className="col-md-12">
                <label htmlFor="color" className="form-label">{labels[4]}</label>
                <input type="text" className="form-control"
                id="field5" name="field5"
                maxLength="255"
                autoComplete="on"
                value={inputs.field5 || ''}
                onChange={updateInput}/>
            </div>
            }
            
            {/* Element 5 - IntColor */}
            {labels[5] && 
            <div className="col-md-12">
                <label htmlFor="color" className="form-label">{labels[5]}</label>
                <input type="text" className="form-control"
                id="field6" name="field6"
                maxLength="255"
                autoComplete="on"
                value={inputs.field6 || ''}
                onChange={updateInput}/>
            </div>
            }
            
            <div className="col-md-12">
                <label htmlFor="color" className="form-label">Notes</label>
                <input type="text" className="form-control"
                id="notes" name="notes"
                maxLength="255"
                value={inputs.notes || ''}
                onChange={updateInput}/>
            </div>
        </div>,
        // Files
        <div>
            {/* Element 6 - Photo (Always shown)*/}
            <div className="col-md-12"><h4>Attachments</h4>
                {Object.keys(attachments).length > 0 ? 
                    <div>
                        {Object.entries(attachments).map(([filename, remote]) => (
                            <button className="btn btn-outline-info" id={remote} name={filename} key={remote} onClick={downloadFile}>{filename}</button>
                        ))}
                    </div>
                    : 
                    <div>
                        <button type="button" className="btn btn-ad-secondary disabled">None</button>
                    </div>    
                }
                <br />

                <input type="file" multiple id="itemAttachments" style={{display: "none"}} onChange={handleFileChange}/>
                <label htmlFor="itemAttachments" className="btn btn-ad-primary">{Object.keys(attachments).length > 0 ? "Add" : "Upload"} Files</label>
                {fileNames.length > 0 && (
                <div>
                    <br />
                    <h5>{uploadComplete ? "Attached Files:" : "Uploading..."}</h5>
                    <div className="d-grid gap-3 d-md-flex">
                        {fileNames.map((name) => (
                            <button type="button" className="btn btn-outline-info" disabled>
                                {uploadComplete ? <span>✅</span> : <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                <span role="status"> {name}</span>
                            </button>
                        ))}
                    </div>
                </div>
                )}
            </div>

            <br />

            {/* PDF container */}
            {pdfData &&
                <>
                    <h4>Document Preview</h4>
                    <br />
                    <div id="nav-controls" className="container">
                        <div className="row justify-content-center">
                            <div className="col-auto mx-auto text-center btn-group" role="group">
                                <button type="button" className="btn btn-dark btn-lg" id="first" onClick={pdfNavControls}>⏮</button>
                                <button type="button" className="btn btn-dark btn-lg" id="prev" onClick={pdfNavControls}>⏪</button>
                                <button type="button" className="btn btn-dark btn-lg" disabled>Page {pageNumber} of {numPages}</button>
                                <button type="button" className="btn btn-dark btn-lg" id="next" onClick={pdfNavControls}>⏩</button>
                                <button type="button" className="btn btn-dark btn-lg" id="last" onClick={pdfNavControls}>⏭</button>
                            </div>

                            <div className="col-auto ml-auto">
                                <button type="button" className="btn btn-ad-danger btn-lg" id="close" onClick={pdfNavControls}>❌</button>
                            </div>
                        </div>
                    </div>

                    <br />

                    <div id="pdf-content" className='container'>
                        <PDFViewer
                            base64Data={pdfData}
                            pageNumber={pageNumber}
                            onDocumentLoadSuccess={onDocumentLoadSuccess}
                            onPageLoadSuccess={onPageLoadSuccess}
                        />
                    </div>
                </>
            }

            {/* Image container */}
            {imageData &&
                <>
                    <h4>Image Preview</h4>
                    <br />
                    <div id="image-content" className='container'>
                        <img src={imageData} width="100%" alt="Preview of the uploaded attachment"/>
                    </div>
                </>
            }

            {/* Previewer Info (no file selected) */}
            {(!pdfData && !imageData) &&
                <>
                    <h4>File Previewer</h4>
                    <div className="alert alert-ad-info" role="alert">
                        <p>Click on an attachment to preview it here. Documents and images in the following formats are supported:</p>
                        <ul>
                            <li>PDF</li>
                            <li>JPEG</li>
                            <li>PNG</li>
                            <li>GIF</li>
                        </ul>
                    </div>
                </>
            }

        </div>
    ];

    return (
        <div className="container" ref={viewerRef}>
            <div className="card">
                <div className="card mt-8">

                    {/* Card Header */}
                    <div className="card-header">
                        <div className="container">
                            <div className="row">
                                <div className="col text-start align-self-center">
                                    {/* "Back to Room" button with confirmation modal target override if fields not empty */}
                                    {dirtyInputs ? (
                                        <button className="btn btn-ad-primary" id="backToRoom" type="button" onClick={updateExitTarget} data-bs-toggle="modal" data-bs-target="#unsavedModal">Back to Room</button>
                                    ) : (
                                        <button className="btn btn-ad-primary" id="backToRoom" type="button" onClick={roomHome}>Back to Room</button>
                                    )}
                                </div>
                                <div className="col-6 text-center">
                                    <h2>{roomData?.roomName} – Update Item</h2>
                                </div>
                                <div className="col text-end align-self-center"></div>
                            </div>
                        </div>
                    </div>

                    {/* Tabs */}
                    <ul className="nav nav-tabs">
                        {tabs.map((tab, index) => (
                        <li className="nav-item" key={index}>
                            <button
                            className={`nav-link ${activeTab === index ? 'active' : ''}`}
                            onClick={() => handleTabClick(index)}
                            >
                            {tab.title}
                            </button>
                        </li>
                        ))}
                    </ul>

                    {/* Card Body - Tab Content */}
                    <div className="card-body">

                        <p ref={alertRef} role="alert" className={success ? "alert alert-ad-success" : "offscreen"}
                            aria-live="assertive">Successfully Updated {selectedCat} – {selectedSubCat}</p>

                        <form className="row g-3" onSubmit={updateItem}>

                            <div className="tab-content">
                                {tabContentComponents.map((contentComponent, index) => (
                                <div
                                    className={`tab-pane ${activeTab === index ? 'active' : ''}`}
                                    key={index}
                                >
                                    {contentComponent}
                                </div>
                                ))}
                            </div>

                            <div className="container gy-4 text-center">
                                <div className="row gx-3">
                                    <div className="col-8">
                                        <button type="submit" disabled={!dirtyInputs} className="btn btn-ad-success btn-lg col-12">Update Item</button>
                                    </div>
                                    <div className="col-4">
                                        <button className="btn btn-ad-danger btn-lg col-12" onClick={event => event.preventDefault()} data-bs-toggle="modal" data-bs-target="#clearFormConfirmModal">Clear Fields</button>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>

            </div>

            <div className="modal fade" id="unsavedModal" data-bs-backdrop="static" data-bs-leopard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Unsaved Changes</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to leave without saving this item?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-ad-secondary" data-bs-dismiss="modal">Keep Working</button>
                            <button type="button" className="btn btn-ad-danger" data-bs-dismiss="modal" onClick={performExit}>Abandon Changes</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="clearFormConfirmModal" data-bs-backdrop="static" data-bs-leopard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Unsaved Changes</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to clear the form without saving this item?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-ad-secondary" data-bs-dismiss="modal">Keep Working</button>
                            <button type="button" className="btn btn-ad-danger" data-bs-dismiss="modal" onClick={clearFields}>Clear Form</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default RoomEditItem;