import { React, useState, useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import axios from '../../api/axios';

import useAuth from "../../hooks/useAuth";

// const ROLES = require('../config/roles');

// const FETCH_ACCOUNT_URL = '/myaccount';
const ROOM_TYPES_URL = '/roomtypes';
const ROOM_STRUCTURE_URL = '/roomstructure';
const SUBCAT_FIELDS_URL = '/fields';
const ALL_PROPERTIES_URL = '/properties';
const ALL_CATS_URL = '/allcategories';
const ALL_SUBCATS_URL = '/allsubcategories';
const CREATE_DEFINITION_URL = '/definition';
const ADD_SUBCAT_TO_DEF_URL = '/definition';
const CREATE_CAT_URL = '/category';
const CREATE_SUBCAT_URL = '/subcategory';
const UPDATE_SUBCAT_URL = '/subcategory';
const CREATE_PROPS_URL = '/properties';
const UPDATE_CAT_NAME_URL = '/catname';
const UPDATE_SUBCAT_NAME_URL = '/subcatname';

function AdminEditRooms() {

    // React hooks
    const navigate = useNavigate();
    const location = useLocation();
    const { auth } = useAuth();

    const [alertMessage, setAlertMessage] = useState(null);

    const [roomTypes, setRoomTypes] = useState(null);
    const [selectedCat, setSelectedCat] = useState(null);
    const [selectedSubCat, setSelectedSubCat] = useState(null);
    const [catOptions, setCatOptions] = useState([]);
    const [subCatOptions, setSubCatOptions] = useState([]);
    const [labels, setLabels] = useState([null, null, null, null, null]);
    const [newLabels, setNewLabels] = useState([null, null, null, null, null]);
    const [roomStructure, setRoomStructure] = useState(null);
    const [properties, setProperties] = useState([]);

    const [newProps, setNewProps] = useState(false);
    const [newPropNames, setNewPropNames] = useState([]);

    const [allCategories, setAllCategories] = useState([]);
    const [allSubCategories, setAllSubCategories] = useState([]);
    const [newCat, setNewCat] = useState(null);
    const [newSubCat, setNewSubCat] = useState(null);

    const [triggerSoftRefresh, setTriggerSoftRefresh] = useState(0);
    
    // For renaming things:
    const [selectedCatName, setSelectedCatName] = useState(null);
    const [selectedSubCatName, setSelectedSubCatName] = useState(null);


    // Navigate back to admin home
    const adminHome = () => {
        navigate('/admin/home');
    }

    // Get selected room ID; navigate back to admin home if none selected
    const params = new URLSearchParams(location.search);
    const roomTypeID = params.get('roomTypeID');
    if (!roomTypeID) {
        adminHome();
    }

    // Get all categories and subcategories
    useEffect(() => {
        try {
            axios.get(ALL_CATS_URL,
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            ).then(
                response => {
                    // Sort categories alphabetically
                    var catNames = [];
                    response?.data.forEach(cat => {
                        catNames.push({
                            value: cat.catID,
                            label: cat.catName
                        }); 
                    })
                    
                    const sortedCats = Object.values(catNames).sort((prev, next) => {
                        // Always bump "Other" to the bottom of the list
                        if (prev.label.toLowerCase() === 'other') return 1;
                        if(prev.label.toLowerCase() < next.label.toLowerCase()) return -1;
                        if(prev.label.toLowerCase() > next.label.toLowerCase()) return 1;
                        return 0;
                    });
                    setAllCategories(sortedCats);
                }
            ).then(function() {
                axios.get(ALL_SUBCATS_URL,
                    {
                        headers: { 'Content-Type': 'application/json' }
                    }
                ).then(
                    response => {
                        // Sort subcategories alphabetically
                        var subCatNames = [];
                        response?.data.forEach(subcat => {
                            subCatNames.push({
                                value: subcat.subCatID,
                                label: subcat.subCatName
                            }); 
                        })
                        
                        const sortedSubCats = Object.values(subCatNames).sort((prev, next) => {
                            // Always bump "Other" to the bottom of the list
                            if (prev.label.toLowerCase() === 'other') return 1;
                            if(prev.label.toLowerCase() < next.label.toLowerCase()) return -1;
                            if(prev.label.toLowerCase() > next.label.toLowerCase()) return 1;
                            return 0;
                        });
                        setAllSubCategories(sortedSubCats);
                    }
                );
            });
        }
        catch (err) {
            console.log(err);
        }
    }, []);

    // Get room types at render and store in effect hook
    useEffect(() => {
        try {
            axios.get(ROOM_TYPES_URL,
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            ).then(
                response => {
                    // Use reduce to transform the array of room type objects to an object with key IDs and name values
                    const roomObj = response?.data?.rooms.reduce((acc, curr) => {
                        acc[curr.roomTypeID] = curr.roomTypeName;
                        return acc;
                    }, {})
                    setRoomTypes(roomObj);
                }
            );
        }
        catch (err) {
            console.log(err);
        }
    }, [roomTypeID]);

    // Get the structure for the selected room
    useEffect(() => {
        try {
            if (roomTypeID) {
                axios.get(ROOM_STRUCTURE_URL + "?roomType=" + roomTypeID,
                {
                    headers: { 'Content-Type': 'application/json' }
                }
                ).then(
                    response => {
                        setRoomStructure(response?.data)
                    }
                );
            }
        }
        catch (err) {
            console.log(err);
        }
    }, [roomTypeID, triggerSoftRefresh]);

    useEffect(() => {
        if (roomStructure) {

            if (selectedCat !== '' && selectedCat in roomStructure) {

                const subCats = roomStructure[selectedCat].subcatList.map((id, index) => {
                    return {
                        label: roomStructure[selectedCat].subcatNameList[index], value: id
                    }
                });
                
                // Sort subcategories alphabetically as above
                const sortedSubCats = subCats.sort((prev, next) => {
                    // Always bump "Other" to the bottom of the list
                    if (prev.label.toLowerCase() === 'other') return 1;
                    if(prev.label.toLowerCase() < next.label.toLowerCase()) return -1;
                    if(prev.label.toLowerCase() > next.label.toLowerCase()) return 1;
                    return 0;
                });
                setSubCatOptions(sortedSubCats);
            }
            else {
                setSubCatOptions([]);
            }
        }
    }, [selectedCat, roomStructure])

    // Use the room structure object to populate cat options (specifically the map)
    useEffect(() => {
        if (roomStructure) {
            // Sort categories alphabetically
            var catNames = [];
            Object.keys(roomStructure).forEach(item => {
                catNames.push({
                    value: item,
                    label: roomStructure[item].parentCatName
                }); 
            })
            
            const sortedCats = Object.values(catNames).sort((prev, next) => {
                // Always bump "Other" to the bottom of the list
                if (prev.label.toLowerCase() === 'other') return 1;
                if(prev.label.toLowerCase() < next.label.toLowerCase()) return -1;
                if(prev.label.toLowerCase() > next.label.toLowerCase()) return 1;
                return 0;
            });
            setCatOptions(sortedCats);
            setSelectedCat(selectedCat ?? '');
        }
    }, [roomStructure, selectedCat]);

    // Get the list of all properties
    useEffect(() => {
        try {
            axios.get(ALL_PROPERTIES_URL,
            {
                headers: { 'Content-Type': 'application/json' }
            }
            ).then(
                response => {
                    setProperties(response?.data?.properties);
                }
            );
        }
        catch (err) {
            console.log(err);
        }
    }, [triggerSoftRefresh]);

    // Triggers when Category is changed; populate the subcategory options and sort them alphabetically
    const handleChangeCat = (event) => {
        const selectedCatID = event?.target?.value ?? selectedCat;
        if (selectedCatID) {
            setSelectedCat(selectedCatID);
            setSelectedCatName(roomStructure[selectedCatID].parentCatName);
        }
        else {
            setSelectedCat(null);
            setSelectedCatName(null);
        }

        setLabels([null, null, null, null, null]);
        setSelectedSubCat('');

        if (selectedCatID !== '' && selectedCatID in roomStructure) {

            const subCats = roomStructure[selectedCatID].subcatList.map((id, index) => {
                return {
                    label: roomStructure[selectedCatID].subcatNameList[index], value: id
                }
            });
            
            // Sort subcategories alphabetically as above
            const sortedSubCats = subCats.sort((prev, next) => {
                // Always bump "Other" to the bottom of the list
                if (prev.label.toLowerCase() === 'other') return 1;
                if(prev.label.toLowerCase() < next.label.toLowerCase()) return -1;
                if(prev.label.toLowerCase() > next.label.toLowerCase()) return 1;
                return 0;
            });
            setSubCatOptions(sortedSubCats);
        }
        else {
            setSubCatOptions([]);
        }
    };

    // Triggers when sub-category is changed; look up the field options and give them
    const handleChangeSubCat = (event) => {
        const selectedSubcatID = event.target.value;
        setSelectedSubCat(selectedSubcatID);

        const subcatNameIndex = roomStructure[selectedCat].subcatList.indexOf(parseInt(selectedSubcatID));
        setSelectedSubCatName(roomStructure[selectedCat].subcatNameList[subcatNameIndex]);

        if (selectedSubcatID !== '') {
            // Get the properties (fields) for this subcat; show the respective fields
            try {
                axios.get(SUBCAT_FIELDS_URL + "?subCatID=" + selectedSubcatID,
                    {
                        headers: { 'Content-Type': 'application/json' }
                    }
                ).then(
                    response => {
                        const fieldList = response?.data.map(field => field.label);
                        setLabels(fieldList);
                        setNewLabels(fieldList);
                    }
                )
            }
            catch (err) {
                console.log(err);
            }
        }
    };

    const updateCatName = (event) => {
        setSelectedCatName(event.target.value);
    }

    const updateSubCatName = (event) => {
        setSelectedSubCatName(event.target.value);
    }

    const updateLabels = (event) => {
        const labelIndex = parseInt(event.target.name[5]) - 1;
        const updatedLabels = [...newLabels];
        if (event.target.value === '') {
            updatedLabels.pop(labelIndex);
        }
        else {
            updatedLabels[labelIndex] = event.target.value;
        }
        setNewLabels(updatedLabels);

        var anyNewLabel = false;
        var newLabelList = [];
        updatedLabels.map(label => {
            const currentLabelNew = !properties.some(prop => prop.propName === label);
            anyNewLabel |= currentLabelNew;
            if (currentLabelNew && label) {
                newLabelList.push(label);
            }
            return anyNewLabel;
        })
        setNewPropNames(newLabelList);
        setNewProps(anyNewLabel);
    }

    const updateNewCat = (event) => {
        setNewCat(event.target.value);
        setAlertMessage();
    }

    const updateNewSubCat = (event) => {
        setNewSubCat(event.target.value);
        setAlertMessage();
    }

    // Add an existing cat to the room structure
    const addCat = () => {
        // Can make this more efficient with a lookup table (reduce, my beloved)
        const foundCatID = allCategories.find(cat => cat.label === newCat);

        if (foundCatID?.value) {
            axios.put(CREATE_DEFINITION_URL,
                JSON.stringify({roomTypeID, catID: foundCatID.value}),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.accessToken}`
                    },
                    withCredentials: true
                }
            ).then(function (response) {
                if (response?.status === 201) {
                    setAlertMessage(<p role="alert" className="alert alert-ad-success" aria-live="assertive">Successfully added "{newCat}" to {roomTypes[roomTypeID]}</p>);
                    setTriggerSoftRefresh(prev => prev + 1);
                    setNewCat('');
                }
            }).catch(function(err) {
                if (err) {
                    
                    if (err?.response?.status === 409) {
                        setAlertMessage(<p role="alert" className="alert alert-ad-warning" aria-live="assertive">Category "{newCat}" already exists in room {roomTypes[roomTypeID]}!</p>)
                    }
                    else {
                        setAlertMessage(<p role="alert" className="alert alert-ad-danger" aria-live="assertive">Unable to add "{newCat}" to {roomTypes[roomTypeID]} - {err?.message}</p>)
                        console.log(err);
                    }
                }
            });
        }
    }

    // Creates a new cat; once created, gets the ID and adds it to the room definiton
    const createNewCat = () => {
        if (newCat) {

            // PUT the new catname to get an ID
            axios.put(CREATE_CAT_URL,
                JSON.stringify({newCat}),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.accessToken}`
                    },
                    withCredentials: true
                }
            ).then(function (response) {
                // 201 means it was created; get the ID from the response and use it to update the room def
                if (response?.status === 201) {
                    const newCatID = response?.data?.catID;
                    if (newCatID) {
                        return axios.put(CREATE_DEFINITION_URL,
                            JSON.stringify({roomTypeID, catID: newCatID}),
                            {
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${auth.accessToken}`
                                },
                                withCredentials: true
                            }
                        );
                    }
                    else {
                        throw Error({message: `Create ${newCat} returned no ID`})
                    }
                }
            // If room def created, operation was successful
            }).then(function (response) {
                if (response?.status === 201) {
                    setAlertMessage(<p role="alert" className="alert alert-ad-success" aria-live="assertive">Successfully created "{newCat}" and added to {roomTypes[roomTypeID]}</p>);
                    setTriggerSoftRefresh(prev => prev + 1);
                    setNewCat('');
                }
            }).catch(function(err) {
                if (err) {
                    setAlertMessage(<p role="alert" className="alert alert-ad-danger" aria-live="assertive">Unable to create category "{newCat}" - {err?.message}</p>)
                    console.log(err);
                }
            });
        }
    }

    // Add an existing subcat to the room structure
    const addSubCat = () => {
        const foundSubcatID = allSubCategories.find(subcat => subcat.label === newSubCat);
        if (foundSubcatID?.value) {
            axios.post(ADD_SUBCAT_TO_DEF_URL,
                JSON.stringify({roomTypeID, catID: selectedCat, newSubcatID: foundSubcatID.value}),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.accessToken}`
                    },
                    withCredentials: true
                }
            ).then(function (response) {
                if (response?.status === 200) {
                    setAlertMessage(<p role="alert" className="alert alert-ad-success" aria-live="assertive">Successfully added "{newSubCat}" to {roomTypes[roomTypeID]} - {selectedCatName}</p>);
                    setTriggerSoftRefresh(prev => prev + 1);
                    setNewSubCat('');
                }
            }).catch(function(err) {
                if (err) {
                    
                    if (err?.response?.status === 409) {
                        setAlertMessage(<p role="alert" className="alert alert-ad-warning" aria-live="assertive">Category "{newSubCat}" already exists in room {roomTypes[roomTypeID]} - {selectedCatName}!</p>)
                    }
                    else {
                        setAlertMessage(<p role="alert" className="alert alert-ad-danger" aria-live="assertive">Unable to add "{newSubCat}" to {roomTypes[roomTypeID]} - {selectedCatName}: {err?.message}</p>)
                        console.log(err);
                    }
                }
            });
        }
    }
    
    const createNewSubCat = () => {
        if (newSubCat) {
            // PUT the new catname to get an ID
            axios.put(CREATE_SUBCAT_URL,
                JSON.stringify({newSubCat}),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.accessToken}`
                    },
                    withCredentials: true
                }
            ).then(function (response) {
                // 201 means it was created; get the ID from the response and use it to update the room def
                if (response?.status === 201) {
                    const newSubCatID = response?.data?.subCatID;
                    if (newSubCatID) {
                        return axios.post(ADD_SUBCAT_TO_DEF_URL,
                            JSON.stringify({roomTypeID, catID: selectedCat, newSubcatID: newSubCatID}),
                            {
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${auth.accessToken}`
                                },
                                withCredentials: true
                            }
                        );
                    }
                    else {
                        throw Error({message: `Create ${newSubCat} returned no ID`})
                    }
                }
            // If room def created, operation was successful
            }).then(function (response) {
                if (response?.status === 200) {
                    setAlertMessage(<p role="alert" className="alert alert-ad-success" aria-live="assertive">Successfully created "{newSubCat}" and added to {selectedCatName}</p>);
                    setTriggerSoftRefresh(prev => prev + 1);
                    setNewSubCat('');
                }
            }).catch(function(err) {
                if (err) {
                    setAlertMessage(<p role="alert" className="alert alert-ad-danger" aria-live="assertive">Unable to create subcategory "{newSubCat}" - {err?.message}</p>)
                    console.log(err);
                }
            });
        }
    };

    // Create any new fields and get their IDs; update the subcat's field list with the resultant new IDs
    const updateFields = () => {
        var fieldIDs = [];

        // Get an array of all property names
        const allPropNames = properties.reduce((acc, curr) => {
            acc.push(curr.propName);
            return acc;
        }, [])

        // Get a list of only new labels that don't already exist
        const newLabelList = newLabels.filter(item => !allPropNames.includes(item));
        
        // Create lookup table for props
        const propArr = properties.reduce((acc, curr) => {
            acc[curr.propID] = curr.propName;
            return acc;
        }, {})

        // Only works for existing labels!
        const updatedFields = newLabels.map(field => fieldIDs.push(Object.keys(propArr).find(key => propArr[key] === field)));

        console.log(fieldIDs);

        // Create the new label(s); then update all fields with the new values
        if (newLabelList.length > 0) {
            axios.put(CREATE_PROPS_URL,
                JSON.stringify({newLabelList: newLabelList}),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.accessToken}`
                    },
                    withCredentials: true
                }
            ).then(function (response) {
                if (response?.status === 201) {
                    const compLabels = {...propArr, ...response?.data};
                    const newUpdatedFields = newLabels.map(field => fieldIDs.push(Object.keys(compLabels).find(key => compLabels[key] === field)));
                    return axios.post(UPDATE_SUBCAT_URL, 
                        JSON.stringify({subcatID: selectedSubCat, fields: newUpdatedFields}),
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${auth.accessToken}`
                            },
                            withCredentials: true
                        })
                }
            }).then(function (response) {
                if (response?.status === 200) {    
                    // CREATED PROPS
                    setAlertMessage(<p role="alert" className="alert alert-ad-success" aria-live="assertive">Successfully created new properties for "{selectedSubCatName}"</p>);
                    setTriggerSoftRefresh(prev => prev + 1);
                }
            }).catch(function(err) {
                if (err) {
                    setAlertMessage(<p role="alert" className="alert alert-ad-danger" aria-live="assertive">Unable to create properties - {err?.message}</p>)
                    console.log(err);
                }
            });
        }
        // Otherwise, no new labels; just update the label list
        else {
            axios.post(UPDATE_SUBCAT_URL, 
                JSON.stringify({subcatID: selectedSubCat, fields: updatedFields}),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.accessToken}`
                    },
                    withCredentials: true
                }).then(function (response) {
                    if (response?.status === 200) {
                        setAlertMessage(<p role="alert" className="alert alert-ad-success" aria-live="assertive">Successfully updated fields for {selectedSubCatName}</p>);
                        setTriggerSoftRefresh(prev => prev + 1);
                    }
                }).catch(function(err) {
                if (err) {
                    setAlertMessage(<p role="alert" className="alert alert-ad-danger" aria-live="assertive">Unable to update properties - {err?.message}</p>)
                    console.log(err);
                }
            });
        }
    }

    
    const renameCat = (event) => {
        event.preventDefault();
        console.log('catname', selectedCatName);

        if (selectedCatName.length > 0) {
            axios.post(UPDATE_CAT_NAME_URL, 
                JSON.stringify({catID: selectedCat, catName: selectedCatName}),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.accessToken}`
                    },
                    withCredentials: true
                }).then(function (response) {
                    if (response?.status === 200) {
                        setAlertMessage(<p role="alert" className="alert alert-ad-success" aria-live="assertive">Successfully updated {selectedCatName}</p>);
                        setTriggerSoftRefresh(prev => prev + 1);
                    }
                }).catch(function(err) {
                if (err) {
                    setAlertMessage(<p role="alert" className="alert alert-ad-danger" aria-live="assertive">Unable to update category name - {err?.message}</p>)
                    console.log(err);
                }
            });
        }
        else {
            setAlertMessage(<p role="alert" className="alert alert-ad-warning" aria-live="assertive">Category name cannot be blank!</p>)
        }
    };

    const renameSubcat = (event) => {
        event.preventDefault();
        console.log('subcatname', selectedSubCatName);

        if (selectedSubCatName.length > 0) {
            axios.post(UPDATE_SUBCAT_NAME_URL, 
                JSON.stringify({subcatID: selectedSubCat, subCatName: selectedSubCatName}),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.accessToken}`
                    },
                    withCredentials: true
                }).then(function (response) {
                    if (response?.status === 200) {
                        setAlertMessage(<p role="alert" className="alert alert-ad-success" aria-live="assertive">Successfully updated {selectedSubCatName}</p>);
                        setTriggerSoftRefresh(prev => prev + 1);
                    }
                }).catch(function(err) {
                if (err) {
                    setAlertMessage(<p role="alert" className="alert alert-ad-danger" aria-live="assertive">Unable to update subcategory name - {err?.message}</p>)
                    console.log(err);
                }
            });
        }
        else {
            setAlertMessage(<p role="alert" className="alert alert-ad-warning" aria-live="assertive">Subcategory name cannot be blank!</p>)
        }
    };


    return (
        <div className="container">
            <div className="card">
                <div className="card mt-8">
                    <div className="card-header">
                        <div className="container">
                            <div className="row">
                                <div className="col text-start align-self-center">
                                    <button className="btn btn-ad-warning" onClick={adminHome}>Admin Home</button>
                                </div>
                                <div className="col-6 text-center">
                                <h2>Admin – Edit {roomTypes && roomTypes[roomTypeID]}</h2>
                                </div>
                                <div className="col text-end align-self-center">
                                    {/* <button className="btn btn-ad-success" type="button" onClick={createRoom}>Create New Room</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">

                        {alertMessage}

                        {/* Category selection with custom combobox (text input with dropdown) */}
                        <h3>{roomTypes && roomTypes[roomTypeID]} Categories:</h3>

                        <p>Select a category to view its subcategories</p>

                        <div className='row'>
                            <div className="col-4">
                                <select id="category" className="form-select" value={selectedCat || ''} onChange={handleChangeCat}>
                                    <option defaultValue value=''>Choose a Category...</option>
                                    {catOptions.map((cat) => (
                                        <option key={cat.value} value={cat.value}>
                                            {cat.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {selectedCat &&
                                <div className="col-8">
                                    <div class="input-group mb-3">
                                        <span class="input-group-text" id="inputGroup-sizing-default">Category Name:</span>
                                        <input type="text" class="form-control" aria-label="Category Name" aria-describedby="inputGroup-sizing-default" id="catName" name="catName"
                                                maxLength="64" value={selectedCatName || ''} onChange={updateCatName}/>
                                        <button class="btn btn-ad-success" disabled={selectedCatName === roomStructure[selectedCat].parentCatName} type="button" onClick={renameCat} id="button-addon2">Rename</button>
                                    </div>
                                </div>
                            }
                            
                        </div>
                        {/* I love this but it doesn't really work here
                        <div className="dropdown form-group">
                            <input
                                type="text"
                                className="form-control"
                                id="customDropdownInput"
                                placeholder="Type or select..."
                                value={inputValue}
                                onChange={handleChange}
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                autoComplete="off"
                            />
                            <ul className="dropdown-menu" aria-labelledby="customDropdownInput">
                                {catOptions
                                .filter(cat => cat.label.toLowerCase().includes(inputValue.toLowerCase()))
                                .map((cat, index) => (
                                    <li key={index}>
                                    <button
                                        className="dropdown-item"
                                        type="button"
                                        onClick={() => handleSelect(cat.label)}
                                    >
                                        {cat.label}
                                    </button>
                                    </li>
                                ))}
                            </ul>
                        </div> */}

                        <p>Create a new category for this room, or select an existing one to add it to the room</p>
                        <div class="input-group mb-3">
                            <span class={"input-group-text " + (newCat ? 'bg-ad-primary': '')} id="inputGroup-sizing-default">New Category:</span>
                            <input type="text" class="form-control" aria-describedby="inputGroup-sizing-default" id="newCatName" name="newCatName"
                                maxLength="32" list="catOptions" value={newCat || ''} onChange={updateNewCat}/>
                            {newCat && ((allCategories.some(cat => cat.label === newCat) ?
                                <button class="input-group-text btn btn-ad-success" id="inputGroup-sizing-default" onClick={addCat}>Add Category</button> :
                                <button class="input-group-text btn btn-ad-info" id="inputGroup-sizing-default" onClick={createNewCat}>Create New Category</button>))}
                                {/* TODO: create new cat here */}
                        </div>

                        <datalist id="catOptions">
                            {allCategories && allCategories.map(cat => {
                                return <option value={cat.label} key={cat.value}/>
                            })}
                        </datalist>

                        <hr />

                        {/* Subcateogry selection */}
                        {selectedCat &&

                        <>
                            <h4>{roomStructure[selectedCat].parentCatName} Subcategories:</h4>
                            
                            <p>Select a subcategory to view and modify its properties</p>
                        
                            <div className='row'>
                                <div className="col-4">
                                    <select id="subCategory" className="form-select" value={selectedSubCat || ''} onChange={handleChangeSubCat}>
                                        <option defaultValue value=''>Choose a Subcategory...</option>
                                        {subCatOptions.map((subCat) => (
                                            <option key={subCat.value} value={subCat.value}>
                                                {subCat.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                
                                
                                {selectedSubCat && 
                                <div className="col-8">
                                    <div class="input-group mb-3">
                                        <span class="input-group-text" id="inputGroup-sizing-default">Subcategory Name:</span>
                                        <input type="text" class="form-control" aria-label="Subcategory Name" aria-describedby="inputGroup-sizing-default" id="subcatName" name="subcatName"
                                            maxLength="64" value={selectedSubCatName || ''} onChange={updateSubCatName}/>
                                            {/* I know this is ugly but it's also kind of beautiful:  */}
                                        <button class="btn btn-ad-success" disabled={selectedSubCatName === roomStructure[selectedCat].subcatNameList[roomStructure[selectedCat].subcatList.indexOf(parseInt(selectedSubCat))]} type="button"onClick={renameSubcat} id="button-addon2">Rename</button>
                                    </div>
                                </div>
                                }

                            </div>

                            <p>Create a new subcategory for this category, or select an existing one to add it</p>
                            <div class="input-group mb-3">
                                <span class={"input-group-text " + (newSubCat ? 'bg-ad-primary': '')} id="inputGroup-sizing-default">New Subcategory:</span>
                                <input type="text" class="form-control" aria-describedby="inputGroup-sizing-default" id="newSubCatName" name="newSubCatName"
                                    maxLength="64" list="subCatOptions" value={newSubCat || ''} onChange={updateNewSubCat}/>
                                {newSubCat && ((allSubCategories.some(subcat => subcat.label === newSubCat) ?
                                    <button class="input-group-text btn btn-ad-success" id="inputGroup-sizing-default" onClick={addSubCat}>Add Subcategory</button> :
                                    <button class="input-group-text btn btn-ad-info" id="inputGroup-sizing-default" onClick={createNewSubCat}>Create New Subcategory</button>))}
                                    {/* TODO: create new cat here */}
                            </div>

                            <datalist id="subCatOptions">
                                {allSubCategories && allSubCategories.map(subcat => {
                                    return <option value={subcat.label} key={subcat.value}/>
                                })}
                            </datalist>

                            <hr />

                            {/* Field options */}
                            {selectedSubCat && 
                            <>
                                <h5>{selectedSubCatName} Properties:</h5>
                                
                                {/* Show the field labels */}
                                <div class="input-group mb-3">
                                    <span class={"input-group-text " + (newLabels[0] ? 'bg-ad-primary': '')} id="inputGroup-sizing-default">Field 1:</span>
                                    <input type="text" class="form-control" aria-describedby="inputGroup-sizing-default" id="field1" name="field1"
                                        maxLength="32" list="datalistOptions" value={newLabels[0] || ''} onChange={updateLabels}/>
                                    {(newLabels[0] !== labels[0]) && (properties.some(prop => prop.propName === newLabels[0]) ?
                                        <span class="input-group-text bg-ad-warning" id="inputGroup-sizing-default">Modified</span> :
                                        <span class="input-group-text bg-ad-info" id="inputGroup-sizing-default">New Property</span>)}
                                </div>
                                <div class="input-group mb-3">
                                    <span class={"input-group-text " + (newLabels[1] ? 'bg-ad-primary': '')} id="inputGroup-sizing-default">Field 2:</span>
                                    <input type="text" class="form-control" aria-describedby="inputGroup-sizing-default" id="field2" name="field2"
                                        maxLength="32" list="datalistOptions" value={newLabels[1] || ''} onChange={updateLabels}/>
                                    {(newLabels[1] !== labels[1]) && (properties.some(prop => prop.propName === newLabels[1]) ?
                                        <span class="input-group-text bg-ad-warning" id="inputGroup-sizing-default">Modified</span> :
                                        <span class="input-group-text bg-ad-info" id="inputGroup-sizing-default">New Property</span>)}
                                </div>
                                <div class="input-group mb-3">
                                    <span class={"input-group-text " + (newLabels[2] ? 'bg-ad-primary': '')} id="inputGroup-sizing-default">Field 3:</span>
                                    <input type="text" class="form-control" aria-describedby="inputGroup-sizing-default" id="field3" name="field3"
                                        maxLength="32" list="datalistOptions" value={newLabels[2] || ''} onChange={updateLabels}/>
                                    {(newLabels[2] !== labels[2]) && (properties.some(prop => prop.propName === newLabels[2]) ?
                                        <span class="input-group-text bg-ad-warning" id="inputGroup-sizing-default">Modified</span> :
                                        <span class="input-group-text bg-ad-info" id="inputGroup-sizing-default">New Property</span>)}
                                </div>
                                <div class="input-group mb-3">
                                    <span class={"input-group-text " + (newLabels[3] ? 'bg-ad-primary': '')} id="inputGroup-sizing-default">Field 4:</span>
                                    <input type="text" class="form-control" aria-describedby="inputGroup-sizing-default" id="field4" name="field4"
                                        maxLength="32" list="datalistOptions" value={newLabels[3] || ''} onChange={updateLabels}/>
                                    {(newLabels[3] !== labels[3]) && (properties.some(prop => prop.propName === newLabels[3]) ?
                                        <span class="input-group-text bg-ad-warning" id="inputGroup-sizing-default">Modified</span> :
                                        <span class="input-group-text bg-ad-info" id="inputGroup-sizing-default">New Property</span>)}
                                </div>
                                <div class="input-group mb-3">
                                    <span class={"input-group-text " + (newLabels[4] ? 'bg-ad-primary': '')} id="inputGroup-sizing-default">Field 5:</span>
                                    <input type="text" class="form-control" aria-describedby="inputGroup-sizing-default" id="field5" name="field5"
                                        maxLength="32" list="datalistOptions" value={newLabels[4] || ''} onChange={updateLabels}/>
                                    {(newLabels[4] !== labels[4]) && (properties.some(prop => prop.propName === newLabels[4]) ?
                                        <span class="input-group-text bg-ad-warning" id="inputGroup-sizing-default">Modified</span> :
                                        <span class="input-group-text bg-ad-info" id="inputGroup-sizing-default">New Property</span>)}
                                </div>
                                <div class="input-group mb-3">
                                    <span class={"input-group-text " + (newLabels[5] ? 'bg-ad-primary': '')} id="inputGroup-sizing-default">Field 6:</span>
                                    <input type="text" class="form-control" aria-describedby="inputGroup-sizing-default" id="field6" name="field6"
                                        maxLength="32" list="datalistOptions" value={newLabels[5] || ''} onChange={updateLabels}/>
                                    {(newLabels[5] !== labels[5]) && (properties.some(prop => prop.propName === newLabels[5]) ?
                                        <span class="input-group-text bg-ad-warning" id="inputGroup-sizing-default">Modified</span> :
                                        <span class="input-group-text bg-ad-info" id="inputGroup-sizing-default">New Property</span>)}
                                </div>

                                {/* Datalist for default properties */}

                                <datalist id="datalistOptions">
                                    {properties && properties.map(val => {
                                        return <option value={val.propName} key={val.propID}/>
                                    })}
                                </datalist>

                                {newProps ? 
                                    <>
                                        <p>New Properties: {newPropNames.map(prop => prop)}</p>
                                        <button class="btn btn-ad-success" disabled={labels.every(item => newLabels.includes(item)) && newLabels.every(item => labels.includes(item))} onClick={updateFields} type="button">Create New Properties</button> 
                                    </>
                                :
                                    <button class="btn btn-ad-success" disabled={labels.every(item => newLabels.includes(item)) && newLabels.every(item => labels.includes(item))} onClick={updateFields} type="button">Update Fields</button>
                                }

                            </>}

                        </>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminEditRooms;