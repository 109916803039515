import {React, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import axios from '../api/axios';
import useAuth from "../hooks/useAuth";

const CATEGORIES_URL = '/categories';
const SUBCAT_FIELDS_URL = '/fields';
const FETCH_ITEMS_URL = '/items';
const ROOM_DETAILS_URL = '/getroom';
const PROJECT_INFO_URL = '/project';

function RoomListItems() {
    const location = useLocation();
    const navigate = useNavigate();
    
    const { auth } = useAuth();
    
    const params = new URLSearchParams(location.search);
    const roomID = params.get('roomID');

    const [details, setDetails] = useState(null);
    const [projectName, setProjectName] = useState(null);
    const [roomData, setRoomData] = useState(null);
    const [items, setItems] = useState(null);
    const [itemTable, setItemTable] = useState(null);
    const [headers, setHeaders] = useState({});

    // Navigate back to selector if room/project not given
    useEffect(() => {
        if (!roomID) {
            navigate('/selectproject');
        }
    }, [roomID, navigate]);

    // Nav to Create Item page on button click (unless no room/proj specified)
    const createItem = () => {
        if (!roomID || !roomData?.projectID) {
            navigate('/selectproject');
        }
        else {
            navigate(`/createitem?roomID=${roomID}&projectID=${roomData.projectID}`);
        }
    };

    const goHome = () => {
        navigate('/selectproject');
    };

    const selectProject = () => {
        if (roomData.projectID) {
            navigate(`/projecthome?projectID=${roomData.projectID}`);
        }
    };

    // Get the room details to figure out room type string; store in effect hook
    useEffect(() => {
        try {
            if (roomID) {
                axios.get(ROOM_DETAILS_URL + "?roomID=" + roomID,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.accessToken}`
                    },
                    withCredentials: true
                }
                ).then(
                    response => setRoomData(response?.data)
                );
            }
        }
        catch (err) {
            console.log(err);
        }

    }, [roomID, auth]);

    // Get the project name
    useEffect(() => {  
        try {
            if (roomData) {
                axios.get(PROJECT_INFO_URL + "?projectID=" + roomData.projectID,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.accessToken}`
                    },
                    withCredentials: true
                }
                ).then(
                    response => setProjectName(response?.data?.project?.name)
                );
            }
        }
        catch (err) {
            console.log(err);
        }
    }, [roomData, auth]);    

    // Get the categories for this room after the room data is stored in the effect hook
    useEffect(() => {
        try {
            if (roomData?.roomType) {
                axios.get(CATEGORIES_URL + "?roomType=" + roomData.roomType,
                {
                    headers: { 'Content-Type': 'application/json' }
                }
                ).then(
                    response => setDetails(response?.data?.cats)
                );
            }
        }
        catch (err) {
            console.log(err);
        }
    }, [roomData]);

    // Fetch all items for this room; parse unique subcats and look up headers for each
    useEffect(() => {
        try {
            if (roomID) {
                // Fetch item data
                axios.get(FETCH_ITEMS_URL + "?roomID=" + roomID,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.accessToken}`
                    },
                    withCredentials: true
                }
                ).then(
                    response => response?.data
                ).then(
                    itemList => {
                        if (itemList?.length > 0) {

                            // Pluck out subcats as array from item list; convert to set to get unique values
                            const subcatList = itemList.map(({subcatID}) => subcatID);
                            var uniqueSubcats = [...new Set(subcatList)];

                            // Iterate through each unique subcat and fetch the header labels; set item state var when done
                            uniqueSubcats.forEach((subcatID, index) => {
                                try {
                                    axios.get(SUBCAT_FIELDS_URL + "?subCatID=" + subcatID,
                                        {
                                            headers: { 'Content-Type': 'application/json' }
                                        }
                                    ).then(
                                        response => {
                                            // Update header state var with each new array
                                            var prev = headers;
                                            prev[subcatID] = [];
                                            response.data.map(field => {
                                                return prev[subcatID].push(field.label);
                                            });
                                            setHeaders(prev);

                                            // Update the items state var after last header is set
                                            if (index === uniqueSubcats.length - 1) {
                                                setItems(itemList);
                                            }
                                        }
                                    );
                                }
                                catch (err) {
                                    console.log(err);
                                }
                            })
                        }
                        else {
                            setItems([]);
                        }
                    }
                );
            }
        }
        catch (err) {
            console.log(err);
        }
    }, [roomID, auth, headers]);

    // Set categories on detail change trigger
    useEffect(() => {

        // Link to navigate to edit item page
        const editItem = (event) => {
            const itemID = event?.currentTarget?.getAttribute('value')
            if (itemID) {
                navigate(`/edititem?itemID=${itemID}`);
            }
        }

        const generateSubCatTable = (items) => {

            return (
                <>
                    <h5>{items[0].subcatName}</h5>
                    <table className="table">
                        <thead>
                            <tr>
                                {headers[items[0].subcatID][0] && <th scope="col">{headers[items[0].subcatID][0] ?? ""}</th>}
                                {headers[items[0].subcatID][1] && <th scope="col">{headers[items[0].subcatID][1] ?? ""}</th>}
                                {headers[items[0].subcatID][2] && <th scope="col">{headers[items[0].subcatID][2] ?? ""}</th>}
                                {headers[items[0].subcatID][3] && <th scope="col">{headers[items[0].subcatID][3] ?? ""}</th>}
                                <th scope="col" width="50">Files</th>
                            </tr>
                        </thead>
                        <tbody>
                        {items.map(item => {
                            return(
                            <tr key={item.itemID} value={item.itemID} className='item' onClick={editItem}>
                                {headers[item.subcatID][0] && <td>{item.field1 || <span className='empty'>Unspecified</span>}</td>}
                                {headers[item.subcatID][1] && <td>{item.field2 || <span className='empty'>N/A</span>}</td>}
                                {headers[item.subcatID][2] && <td>{item.field3 || <span className='empty'>N/A</span>}</td>}
                                {headers[item.subcatID][3] && <td>{item.field4 || <span className='empty'>N/A</span>}</td>}
                                <td>{item.files && Object.keys(JSON.parse(item.files)).length > 0 ? "📁" + Object.keys(JSON.parse(item.files)).length  : ""}</td>
                            </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </>
            );
        };
        const generateTables = () => {
            // Initialize table while loading
            setItemTable(
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            );
           
            // Check if items exist yet
            if (items) {
                // If object exists and contains items
                if (items?.length > 0) {    
                    var tableGen = [];
                    var currentCat = '';
                    var currentSubCat = '';
                    const slices = [];                

                    for (let i = 0; i < items.length; i++) {
                        if (items[i].catID !== currentCat || items[i].subcatID !== currentSubCat) {
                            slices.push(i);
                            currentSubCat = items[i].subcatID;
                            currentCat = items[i].catID;
                        }
                    }

                    // Table slices will each be own table
                    if (slices.length > 1) {
                        for (let i = 0; i < slices.length; i++) {
                            const catItems = items.slice(slices[i], slices[i+1]);
                            
                            // Get the cat info from the first item
                            if (i === 0 || catItems[0].catID !== currentCat) {
                                if (i > 0) {
                                    tableGen.push(<br/>);
                                }
                                tableGen.push(<h2>{catItems[0].catName}</h2>)
                                currentCat = catItems[0].catID;
                            }
                            tableGen.push(generateSubCatTable(catItems));
                        }
                    }
                    else {
                        tableGen.push(generateSubCatTable(items));
                    }
                    setItemTable(tableGen);
                }
                // Items list is empty (not null); alert that room is empty
                else {
                    setItemTable(
                        <div className="alert alert-ad-warning" role="alert">
                            This room is empty.
                        </div>
                    );
                }
            }
        };
        generateTables();
    }, [items, details, navigate, headers]);

    const editRoom = () => {
        navigate(`/createroom?projectID=${roomData.projectID}&roomID=${roomID}`);
    }

    return (
        <div className="container">
            <div className="card">
                <div className="card mt-8">

                    {/* Header with breadcrumb navigation */}
                    <div className="card-header">
                        <div className="container">
                            <div className="d-flex justify-content-center">
                                <nav className="breadcrumb" style={{"--bs-breadcrumb-divider": '">"'}} aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item" onClick={goHome}>Home</li>
                                        {projectName && <li class="breadcrumb-item" onClick={selectProject}>{projectName}</li>}
                                        {projectName && roomData?.roomName && <li class="breadcrumb-item active" aria-current="page">{roomData?.roomName}</li>}
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>

                    {/* Card body with controls at top; item table following */}
                    <div className="card-body">

                        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                            <button className="btn btn-ad-success" type="button" onClick={createItem}>Create New Item</button>
                            <button className="btn btn-ad-warning" type="button" onClick={editRoom}>Edit Room</button>
                        </div>

                        {itemTable}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RoomListItems;